import React from 'react'

const PrivacyPolicy = () => {
    return (
        <article className="policy-parent">
            <div className="flex-container policy-title">
                <h1>PRIVACY AND DATA POLICY</h1>
                <h1>ISSUED BY ROBUST GAMES LIMITED</h1>
                <h2>Last Updated: 16 November 2021.</h2>
            </div>
            <ol start="1">
                <li><b>INTRODUCTION AND GENERAL TERMS</b></li>
            </ol>
            <div className="policy-content">
                <p>These terms apply to the use of games and other products and services developed and/or published by Robust Games Limited. (&ldquo;<strong>Robust Games</strong>&rdquo; / &ldquo;<strong>we</strong>&rdquo; / &ldquo;<strong>us</strong>&rdquo;). Our registered office is at Preston Park House, South Road, Brighton, East Sussex, England, BN1 6SB.</p>
                <p>We are a company registered in England, with company number 13285961.</p>
                <p>This privacy and data policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;) applies and has effect in respect of all games, related online services (including online network play connectivity and interactivity) and other software and products made available by us (together the &ldquo;<strong>Games</strong>&rdquo;), as well as any other online features relating to the Games, our websites (including, without limitation,&nbsp;<a href="https://www.robust.games/">www.robust.games</a>) (together, the &ldquo;<strong>Websites</strong>&rdquo;) and our online communities (&ldquo;<strong>Communities</strong>&rdquo;).</p>
                <p>Together, the Games, Websites and Communities are referred to herein as the &ldquo;<strong>Online Services</strong>&rdquo;.</p>
                <p><strong>Except where it may otherwise be expressly set out in the terms of use for our relevant Online Service, our Online Service is not marketed to users under the age of 13 and those aged under-13 should not use our Online Services.</strong></p>
                <p><strong>We do not use our Online Services to knowingly solicit information from, or market to, those under the age of 13.</strong></p>
                <table className="policy-inline-table">
                    <tbody>
                        <tr>
                            <td>If you have any questions or comments about this Privacy Policy, please contact us at&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>.</td>
                        </tr>
                    </tbody>
                </table>
                <p>We are committed to protecting and respecting your privacy. &nbsp;This Privacy Policy explains the basis on which personal data we collect from you will be processed by us or on our behalf. Where we decide the purpose or means for which personal data you supply through these Online Services is processed, we are the &ldquo;controller&rdquo; for the purposes of the General Data Protection Regulation 2016/679&nbsp;(&ldquo;<strong>GDPR</strong>&rdquo;)&nbsp;and the UK Data Protection Act 2018&nbsp;(&ldquo;<strong>UKDPA</strong>&rdquo;). We will comply with all applicable data protection laws, including the GDPR, UKDPA and the California Consumer Privacy Act 2018 (&ldquo;<strong>CCPA</strong>&ldquo;).</p>
                <p>Please read this Privacy Policy carefully as it contains important information about the following:</p>
                <ul>
                    <li>What information we may collect about you;</li>
                    <li>How we will use information we collect about you;</li>
                    <li>Whether we will disclose your details to anyone else; and</li>
                    <li>Your choices and rights regarding the personal data you have provided to us.</li>
                </ul>
                <p>This Privacy Policy should be read in conjunction with our terms of use as may be applicable in respect of the relevant Online Service. Where terms of use are applicable, they will be made available via the relevant Online Service.</p>
                <p>The Online Services may contain hyperlinks to services owned and operated by third parties. These third party services may have their own privacy policies and we recommend that you review them. They will govern the use of personal data that you submit or which is collected by cookies and other tracking technologies whilst using these services. We do not accept any responsibility or liability for the privacy practices of such third party services and your use of these is at your own risk.</p>
                <p>We may make changes to this Privacy Policy in the future, which will be made available here. You should check here from time to time to ensure you are aware of any changes. Where appropriate, we may notify you of changes by email or through the Game or Community.</p>
            </div>
            <ol start="2">
                <li><b>INFORMATION WE MAY COLLECT ABOUT YOU</b></li>
            </ol>
            <div className="policy-content">
                <table style={{borderCollapse: `collapse`, width: `100%`, border: `1`}}>
                <tbody>
                    <tr>
                        <td style={{width: `99.7524%%`}}>
                        <p><strong>Information provided by you when using a Game or Community</strong></p>
                        <p><strong>(&ldquo;Game Information&rdquo;)</strong></p>
                        <ul>
                            <li>Your mobile device&rsquo;s unique device ID (for our mobile Games only);</li>
                            <li>Your user/account avatar (where applicable in Games and Communities only);</li>
                            <li>Your user/account username (where applicable);</li>
                            <li>Your user/account ID (where applicable);</li>
                            <li>and &lsquo;Events&rsquo; related to your gameplay in our Games, such as your achievement of a milestone, the occurrence of other particular events during your gameplay (such as completing a tutorial, game level, making a purchase or viewing an advertisement).</li>
                        </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `99.7524%%`}}>
                        <p><strong>Information provided by you when using the Communities or that may be provided by you when you contact us for support or for newsletter sign-ups</strong></p>
                        <p><strong>(&ldquo;Contact Information&rdquo;)</strong></p>
                        <ul>
                            <li>Your name;</li>
                            <li>Your Steam ID / Steam profile identifier / display name;</li>
                            <li>Your address, telephone number and email address; and</li>
                            <li>Your password (for Communities and otherwise where applicable).</li>
                        </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `99.7524%%`}}>
                        <p><strong>User-generated content hosted and displayed in our Communities or Games</strong></p>
                        <p><strong>(&ldquo;User Content&rdquo;)</strong></p>
                        <p>When using our Communities, you may decide to post information that may or may not contain personal data, in accordance with the rules applicable to our Communities (such as the relevant forum rules). Similarly, when using some of our Games (where this functionality is provided by us) you may decide to post certain content such as maps and in-game character dialogue, in accordance with the terms applicable for the relevant Game (e.g. the relevant end user licence agreement).</p>
                        <p>We cannot control the wider dissemination via the public internet of personal data that you may choose to post in User Content.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `99.7524%%`}}>
                        <p><strong>Information about your activities</strong></p>
                        <p><strong>(&ldquo;Analytics&rdquo;)</strong></p>
                        <p>We may collect technical information about your use of our Websites or Communities through the use of tracking technologies and analytics.</p>
                        <p>Personal data we may collect includes the following:</p>
                        <ul>
                            <li>Your IP address and MAC address;</li>
                            <li>Device information (e.g. device identifier and browser)</li>
                            <li>Game results, progress and timings; and</li>
                            <li>Page usage (e.g. buttons clicked, screens viewed).</li>
                        </ul>
                        <p><strong>Cookies</strong></p>
                        <p>Analytics may involve the use of &lsquo;cookies&rsquo;.</p>
                        <p>A cookie is a string of information that a website stores on a visitor&rsquo;s computer, and that the visitor&rsquo;s browser provides to the website each time the visitor returns. Cookies are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Robust Games uses cookies to help Robust Games identify and track visitors, their usage of a Robust Games website, and their website access preferences.</p>
                        <p>The following cookies are used on the Websites (as may differ between the different sites):</p>

                <table style={{borderCollapse: `collapse`, width: `100%`, border: `1`}}>
                <tbody>
                    <tr>
                        <td><strong>Cookie Name</strong></td>
                        <td><strong>Description</strong></td>
                        <td><strong>Link</strong></td>
                    </tr>
                    <tr>
                        <td>Google Analytics</td>
                        <td>Google Analytics is a service offered by Google that generates detailed statistics about a website&rsquo;s traffic and traffic sources and measures conversions and sales.</td>
                        <td><a href="https://www.google.com/analytics/">https://www.google.com/analytics/</a></td>
                    </tr>
                </tbody>
                </table>

                <p>We may also use certain other cookies which are essential for the functionality of our service. Essential cookies are automatically applied. You may delete and block all cookies, but parts of the service may not work properly.</p>
                <p>Our cookies are applied only for the duration necessary for them to fulfil their function.</p>
                <h4>How can I change my cookie settings?</h4>
                <p>Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set, visit&nbsp;<a href="http://www.aboutcookies.org/">www.aboutcookies.org</a>. Find out how to manage cookies on popular browsers:</p>
                <ul>
                    <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en">Google Chrome (here)</a></li>
                    <li><a href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">Microsoft Edge (here)</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox (here)</a></li>
                    <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer (here)</a></li>
                    <li><a href="https://www.opera.com/help/tutorials/security/privacy/">Opera (here)</a></li>
                    <li><a href="https://support.apple.com/en-gb/safari">Apple Safari (here)</a></li>
                </ul>
                <p>To find information relating to other browsers, visit the browser developer&rsquo;s website.</p>
                <p>For mobile, your mobile device manufacturer may provide you with similar measures for control over SDKs or equivalent technologies set on your device, manageable through your device settings.</p>
                <p>To opt out of being tracked by Google Analytics across all websites, visit&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout.</a></p>
                </td>
                </tr>
                <tr>
                    <td style={{width: `99.7524%%`}}>
                    <p><strong>Information about you collected from third parties</strong></p>
                    <p><strong>(&ldquo;Third Party Account &nbsp;Information&rdquo;)</strong></p>
                    <p>If you choose to access or log into our Online Services (including without limitation our Games) via a third party social media platform such as Facebook, we may collect and store personal data and other information made available by that third party platform, such as your profile username, profile picture, user ID and public information about your friends.</p>
                    <p>We may also collect technical information in order to ensure that our Online Service connects correctly with your social media user account. This information may be obtained via a Facebook cookie or other similar device in our Online Service. The third party platform is responsible for obtaining your consent before sharing such data with us.</p>
                    </td>
                </tr>
                </tbody>
                </table>
            </div>
            <ol start="3">
                <li><b>WHY WE COLLECT INFORMATION ABOUT YOU</b></li>
            </ol>
            <div className="policy-content">
                <table style={{borderCollapse: `collapse`, width: `100%`, border: `1`}}>
                <tbody>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To provide the Games</strong>&nbsp;<strong>to you</strong></p>
                        <p>We will use information about you (including the Game Information, Contact Information, User Content (where applicable) and Third Party Account Information) to deliver our Games to you under the terms of use agreed between us. The processing of information in this way is necessary for us to record your progress and current status within a Game, and to ensure the Games deliver the features promised and function properly.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To enable in-Game purchases</strong></p>
                        <p>If you make purchases through the Online Services, your order and Game Information and Contact Information may be processed so that you can access these features within the Games.</p>
                        <p>For the above purposes, we rely upon the legal basis of performance of a contract.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To provide the Communities and Preventing Spam</strong></p>
                        <p>We process your Contact Information, User Content and certain of your Game Information to provide you with the Communities under the relevant terms of use agreed between us.</p>
                        <p>Certain Game Information and User Content which you supply through our Online Service may be shared with other users of the Online Service. In some cases you may be able to select specific recipients (for example, if you are sending a forum post, or a private message, to a particular forum or individual) then only those recipients will receive it, however in all other cases, your information may be made publically available.</p>
                        <p>We may make your Game Information and User Content available to forum moderators. Moderators are crucial to the Communities and help make them the place that they are. Moderators are directed to only act in accordance with our instructions and to adhere to the moderator rules.</p>
                        <p>Spam is a problem for many forums and websites. In order to minimise this we use Stop Forum Spam to check that any new registration is not spam. In order to do this certain information is provided to Stop Forum Spam for the sole purpose of checking that it is not spam. You can find out more about how Stop Forum Spam use this information on their website available here:&nbsp;<a href="https://stopforumspam.com/privacy">https://stopforumspam.com/privacy</a></p>
                        <p>For the above purposes, we rely upon the legal basis of performance of a contract and our legitimate interests, being our commercial interests to moderate the services and to prevent spam.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To respond to your enquiries and requests for support</strong></p>
                        <p>We may process Submitted Information so that we are able to properly respond to your enquiries and support requests, in accordance with the terms of use agreed between us.</p>
                        <p>As part of this we may also share this Submitted Information with the developers of the Games that we publish.</p>
                        <p>For the above purposes, we rely upon the legal basis of performance of a contract and our legitimate interests, being our commercial interests to respond to your queries and requests for support.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>For advertising purposes</strong></p>
                        <p>For some of our Online Services, we may process Submitted Information to show you advertisements for third party services. Please note that we use third party providers of adverts and the adverts&rsquo; content is provided by these third parties and not Robust Games. For advertising on our Online Services your personal data is processed for this purpose on the basis of our legitimate interests, being our commercial interests for advertisement-supported services.</p>
                        <p>For some of our Online Services, Analytics information may be shared with advertisers on an anonymous aggregate basis. We may also publicise anonymous, aggregate statistics through our websites or other channels although this will not include your personal data.</p>
                        <p>Some of our Online Services integrate with AdSense, Google Analytics and GG Software. &nbsp;Analytics data will be processed and tracked by those third party services, and you can find out more about the way they use this information at the following locations:</p>
                        <ul>
                            <li>Google Analytics and AdSense &nbsp;<a href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a></li>
                        </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To prevent fraudulent or illegal activity</strong></p>
                        <p>We may process your Submitted Information for our legitimate interests of ensuring that your use of our Online Services is lawful, to prevent disruption to our Online Service, to enforce our rights, to protect any individual or otherwise to ensure our compliance with our legal obligations.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To help us to improve the Online Services and help us fix any problems</strong></p>
                        <p>We may process information about you (being the Submitted Information or any part thereof) so that we can analyse and improve our Online Services.</p>
                        <p>This processing is necessary for us to pursue our legitimate interests, being our commercial interests of: (i) ensuring that our Online Services function properly so that you and other users have the best experience when, for example, playing the Games and using the other Online Services; (ii) improving the quality of our Online Services, and providing a better experience to our users; and (iii) identifying and correcting any bugs or problems with the Online Services.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To help us to understand your usage of the Online Services</strong></p>
                        <p>We may process information (being the Submitted Information or any part thereof) to understand how users use our Online Services, and to compile statistical reports regarding that activity, as well as understanding how users progress within a particular Online Service such as a Game. This processing is necessary for us to pursue our legitimate interests, being our commercial interests in continually improving the Online Services, understanding and fixing problems (such as when users may frequently be &lsquo;stuck&rsquo; in a particular Game level) and to improve our future products and services.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `98.762%`}}>
                        <p><strong>To keep you up to date</strong></p>
                        <p>Where you have consented to receive such news and products, we use your Contact Information to send you emails in order to keep you informed of news and products (such as with our email newsletters that you might like to sign up to). You can withdraw your consent, such as to receive our email newsletters, at any time and including by emailing us at <a href="mailto:contact@robustgames.org">contact@robustgames.org</a>.</p>
                        <p>We may use vendors such as MailChimp to assist us with managing our email newsletter databases and to assist us with sending emails to you. You can find out more about how MailChimp process personal data here:</p>
                        <ul>
                            <li>Mailchimp:&nbsp;<a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a></li>
                        </ul>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <ol start="4">
                <li><b>DATA SHARING</b></li>
            </ol>
            <div className="policy-content">
                <p><strong>We will share your information with third parties only in the ways that are described in this Privacy Policy.</strong></p>
                <br/>
                <p><strong>Group members, personnel, suppliers or subcontractors:</strong>&nbsp;We keep your information confidential, but may disclose it to any member of our group (which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the Companies Act 2006), our personnel, suppliers or subcontractors insofar as it is reasonably necessary for the purposes set out in this Privacy Policy. However, this is on the basis that they do not make independent use of the information, and have agreed to safeguard it.</p>
                <br/>
                <p><strong>Merger or acquisition:</strong>&nbsp;If we are involved in a merger, acquisition, transfer or sale of all or a portion of our assets, you will be notified via email, account message and/or a prominent notice on our website of any change in ownership or uses of this information, as well as any choices you may have regarding this information.</p>
                <br/>
                <p><strong>Required by law:</strong>&nbsp;We may disclose your information to the extent that we are required to do so by law (which may include to government bodies and law enforcement agencies); in connection with any legal proceedings or prospective legal proceedings; and in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention).</p>
                <br/>
                <p><strong>Enforcement:</strong>&nbsp;We may also disclose your personal data to third parties (e.g. mobile network operators, gaming platform operators, game console manufacturers or networks) in order to enforce or apply the terms of agreements, to investigate potential breaches or to protect the rights, property or safety of Robust Games, our customers or others.</p>
                <br/>
                <p><strong>Digital Content Stores: &nbsp;</strong>Where our Game is downloaded through a third party store service such as Google Play or Apple&rsquo;s App Store, we may disclose your personal data to Google and/or Apple respectively for the purposes of facilitating any in-Game payments made through these platforms.</p>
                <br/>
                <p><strong>Analytics</strong>: On occasion we may also disclose your personal information for the purpose of improving and optimising our Online Services to deliver a better experience for our users.</p>
                <br/>
                <p><strong>Social media</strong>: We may disclose your personal information to social media platforms, including without limitation Facebook or Twitter, such as where you connect your account for an Online Service with your social media account.</p>
            </div>
            <ol start="5">
                <li><b>AUTOMATED DECISION MAKING</b></li>
            </ol>
            <div className="policy-content">
                <p>We may use automated decision-making methods which do not involve human involvement to process your information only in the ways that are described in this Privacy Policy.</p>
                <p>For instance we use StopForumSpam, and if the new user is flagged as spamming the user will be informed of the outcome and will have the right to either have this decision re-considered or obtain human intervention to express his or her point of view and to contest the decision. If this applies to you, please contact us at&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>.&nbsp;&nbsp;</p>
            </div>
            <ol start="6">
                <li><b>CONSUMER CONTROL AND OPT-OUT OPTIONS</b></li>
            </ol>
            <div className="policy-content">
                <p>If you do not wish to be part of a lookalike campaign, receive notifications from us or be served with targeted advertisements you can opt-out by changing your advertising and notification settings on your device directly. &nbsp;If you are using an iOS device, you can opt out of personalised adverts by visiting&nbsp;<a href="https://oo.apple.com/">https://oo.apple.com</a>. For Android devices, you can visit&nbsp;<a href="https://support.google.com/ads/answer/2662922?hl=en-GB">https://support.google.com/ads/answer/2662922?hl=en-GB</a>&nbsp;for information about opting out of personalized adverts.</p>
                <p>We will obtain your consent to collect your information to be used for marketing purposes that require consent. If you prefer that we do not use your data in this way, let us know by writing to&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>&nbsp;or on the relevant forms or check-boxes that we may use to collect your data.</p>
            </div>
            <ol start="7">
                <li><b>YOUR RIGHTS IN RELATION TO PERSONAL DATA WHICH WE PROCESS RELATING TO YOU</b></li>
            </ol>
            <div className="policy-content">
                <p>You have the following rights over the way we process personal data relating to you, as set out in the table below. &nbsp;We aim to comply to requests without undue delay and within one month at the latest.</p>
                <p>To make a request, please let our Data Protection Officer know via:&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>.&nbsp;&nbsp;</p>
                <table style={{borderCollapse: `collapse`, width: `100%`, border: `1`}}>
                <tbody>
                    <tr>
                        <td style={{width: `99.694%`}}>
                        <p><strong>Ask for a copy of data we are processing about you and have inaccuracies corrected</strong></p>
                        <p>You have the right to request a copy of the personal data we hold about you and to have any inaccuracies corrected.</p>
                        <p>You can download a copy of your Game account information by following the instructions in your account settings.</p>
                        <p>We will use reasonable efforts, to the extent required by law, to supply, correct or delete personal data held about you on our files (and with any third parties to whom it has been disclosed to).</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `99.694%`}}>
                        <p><strong>Objecting to us processing data about you</strong></p>
                        <p>You can ask us to restrict, stop processing or delete your personal data if:</p>
                        <ul>
                            <li>you consented to our processing the personal data and have withdrawn that consent;</li>
                            <li>we no longer need to process that personal data for the reason it was collected;</li>
                            <li>we are processing that personal data because it is in the public interest or it is in order to pursue a legitimate interest of Robust Games or a third party, you don&rsquo;t agree with that processing and there is no overriding legitimate interest for us to continue processing it;</li>
                            <li>the personal data was unlawfully processed;</li>
                            <li>
                                you need the personal data to be deleted in order to comply with legal obligations; and/or
                                <ul>
                                    <li>the personal data is processed in relation to the offer of a service to a child.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>You can delete your accounts by following the instructions in your account settings, where applicable depending on the relevant Online Service.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `99.694%`}}>
                        <p><strong>Obtain a machine-readable copy of your personal data, which you can use with another service provider</strong></p>
                        <ul>
                            <li>If (i) we are processing data in order to perform our obligations to you or because you consented and (ii) that processing is carried out by automated means, we will help you to move, copy or transfer your personal data to other IT systems.</li>
                            <li>If you request, we will supply you with the relevant personal data in CSV format. &nbsp;Where it is technically feasible, you can ask us to send this information directly to another IT system provider if you prefer.</li>
                        </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: `99.694%`}}>
                        <p><strong>Make a complaint to a Supervisory Authority</strong></p>
                        <ul>
                            <li>If you are unhappy with the way we are processing your personal data, please let us know by contacting us via the support services.</li>
                            <li>If you do not agree with the way we have processed your data or responded to your concerns, an alternative is to submit a complaint to a Data Protection Supervisory Authority.</li>
                        </ul>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <ol start="8">
                <li><b>DATA RETENTION</b></li>
            </ol>
            <div className="policy-content">
                <p>We will only retain your information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting or reporting requirements.</p>
            </div>
            <ol start="9">
                <li><b>CHILDREN</b></li>
            </ol>
            <div className="policy-content">
                <p>We do not use our Online Services to knowingly solicit information from, or market to, children under the age of 13. In the event that we learn that we have collected personal data from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13 years of age, please contact us at&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>.&nbsp;&nbsp;</p>
            </div>
            <ol start="10">
                <li><b>SECURITY</b></li>
            </ol>
            <div className="policy-content">
                <p>Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through our Online Services; any transmission is at your own risk</p>
            </div>
            <ol start="11">
                <li><b>INTERNATIONAL DATA TRANSFERS</b></li>
            </ol>
            <div className="policy-content">
                <p>The data we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (&ldquo;<strong>EEA</strong>&rdquo;). It may also be processed by staff that operate outside the EEA and work for us or our suppliers. These staff may be engaged in the fulfilment of your orders, the processing of your payment details, the maintenance of the Service, and the provision of support services. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
                <p>Where your personal data is transferred outside of the EEA to a territory not subject to an adequacy decision by the European Commission, we have agreements in place with the relevant parties which include either (i) standard data protection clauses adopted by the relevant data protection regulator and approved by the European Commission or (ii) standard data protection clauses adopted by the European Commission, to ensure that appropriate safeguards are used to protect your personal data. Alternatively, we may put in place other appropriate safeguards. If you require more information about these safeguards, you can contact us at&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>.</p>
            </div>
            <ol start="12">
                <li><b>CALIFORNIA RESIDENTS &amp; CCPA</b></li>
            </ol>
            <div className="policy-content">
                <p>If you are a California resident, as defined in Section 17014 of Title 18 of the California Code of Regulations, you have certain rights in respect of the personal information we hold about you.</p>
                <p>If you have questions or concerns about our privacy policies and practices, please contact us at the details set out at the end of this Privacy Policy.</p>
                <p>Please be aware that we have disclosed for our business purposes your personal information to third parties within the last 12 months, in accordance with this Privacy Policy.</p>
                <p>Please see above for:</p>
                <ul>
                    <li>the date on which this Privacy Policy was last updated (at the top of this Privacy Policy);</li>
                    <li>categories of personal information collected about you in the last 12 months (see paragraph 2);</li>
                    <li>categories of sources from which the personal information was collected (see paragraphs 2 and 3);</li>
                    <li>purposes for collecting personal information (see paragraph 3);</li>
                    <li>categories of third parties with whom we share personal information (see paragraph 3 and 4);</li>
                    <li>categories of personal information we have disclosed within the last 12 months (see paragraphs 2 and 4); and</li>
                    <li>specific pieces of personal information we hold about you (see paragraphs 2 and 8).</li>
                </ul>
                <p>We do not knowingly sell the personal information of minors under 16 years of age, except with affirmative authorisation in the case of those aged between 13 and 16.</p>
                <p>We do not sell personal information&nbsp;<strong>except</strong>&nbsp;for: (i) our advertising and analytics purposes in connection with our Websites, such as disclosing personal information to our analytics and advertising partners; and (ii) to social media platforms including, without limitation, Facebook in connection with providing our services (e.g. as part of connecting your social media account to your Online Services account), as each are further detailed in this Privacy Policy. You have the right to opt-out of the sale and you can learn more about opting out&nbsp;<a href="https://optout.networkadvertising.org/">here</a>.</p>
                <p>You can designate an authorised agent to make a request under the CCPA on your behalf. The authorized agent must submit proof that they have been authorized by you to act on your behalf and proof of their own identity.</p>
                <p>You have the right to:</p>
                <ul>
                    <li>request access to your personal information;</li>
                    <li>request deletion of your personal information;</li>
                    <li>opt-out of the sale of your personal information; and</li>
                    <li>not be discriminated against for exercising any of your rights under the California Consumer Privacy Act of 2018 (CCPA).</li>
                </ul>
                <p>To make a verifiable consumer request, please contact us by email at&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>. We will attempt to verify your request within seven (7) days and may verify your request by asking for proof of identity, such as your device ID and/or user ID.</p>
            </div>
            <ol start="13">
                <li><b>CONTACT INFORMATION</b></li>
            </ol>
            <div className="policy-content">
                <p>All questions, comments or enquiries should be directed to Robust Games at&nbsp;<a href="mailto:contact@robustgames.org">contact@robustgames.org</a>. &nbsp;We will endeavor to respond to any query or questions within two business days.</p>
            </div>
            <div className="policy-content">
                <p>© {new Date().getFullYear()} Robust Games Limited. All trademarks are the property of the relevant owners. All rights reserved.</p>
            </div>
        </article>
    )
}

export default PrivacyPolicy