import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"

import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

import PrivacyPolicy from "../components/privacy-policy"

const PrivacyPage = () => {

  const { background } = useStaticQuery(
      graphql`
          query {
              background: file(relativePath: {eq: "seamless.webp"}) {
                  childImageSharp {
                  gatsbyImageData(
                      width: 4176,
                      placeholder: BLURRED,
                      formats: [AUTO, WEBP, AVIF],
                      quality: 100,
                      webpOptions: {quality: 100}
                  )
                  }
              }
          }
      `
  )

  const pluginImage = getImage(background)
  const bgImage = convertToBgImage(pluginImage)

  return (
    <BackgroundImage
      className="privacy-background"
      Tag="section"
      {...bgImage}
      style={{ backgroundRepeat: 'repeat-y', backgroundSize: '100%' }}
      preserveStackingContext>
        <Layout>
          <Seo title="Robust Games"/>
          <PrivacyPolicy/>
        </Layout>
      </BackgroundImage>
  )
}

export default PrivacyPage
